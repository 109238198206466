import { combineReducers } from 'redux';
import standingsReducer from './reducers/standings.reducer';
import fixturesReducer from './reducers/fixtures.reducer';
import fixtureDetailsReducer from './reducers/fixture-details.reducer';
import teamsReducer from './reducers/teams.reducer';
import newsReducer from './reducers/news.reducer';
import authReducer from './reducers/auth.reducer';
import usersReducer from './reducers/users.reducer';
import quizesReducer from './reducers/quizes.reducer';
import avatarReducer from './reducers/avatar.reducer';
import coinsReducer from './reducers/coins.reducer';
import gameReducer from './reducers/game.reducer';

export default combineReducers({
	standings: standingsReducer,
	fixtures: fixturesReducer,
	fixturesDetails: fixtureDetailsReducer,
	teams: teamsReducer,
	news: newsReducer,
	auth: authReducer,
	users: usersReducer,
	quizes: quizesReducer,
	avatar: avatarReducer,
	coins: coinsReducer,
	game: gameReducer
});