import {
	SET_LOGGED_USER,
	SET_PROFILE_DATA,
	START_LOADING_USERS,
	STOP_LOADING_USERS,
	START_LOGIN_LOADING,
	STOP_LOGIN_LOADING,
	SET_LOGGED_USER_GAME_VOTES_COUNT,
	START_WIDGET_BUYING_LOADING,
	STOP_WIDGET_BUYING_LOADING,
} from '../actions/users.actions';

const initialState = {
	isLoading: true,
	isLoginLoading: true,
	loggedUser: null,
	profileData: null,
	isWidgetBuyingLoading: false,
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_PROFILE_DATA:
		return {
			...state,
			profileData: action.payload,
		};
	case SET_LOGGED_USER:
		return {
			...state,
			loggedUser: action.payload,
		};
	case SET_LOGGED_USER_GAME_VOTES_COUNT:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				votes: {
					...state.loggedUser.votes,
					gameVotesCount: action.payload
				}
			},
		};
	case START_LOGIN_LOADING:
		return {
			...state,
			isLoginLoading: true,
		};
	case STOP_LOGIN_LOADING:
		return {
			...state,
			isLoginLoading: false,
		};
	case START_LOADING_USERS:
		return {
			...state,
			isLoading: true,
		};
	case STOP_LOADING_USERS:
		return {
			...state,
			isLoading: false,
		};
	case START_WIDGET_BUYING_LOADING:
		return {
			...state,
			isWidgetBuyingLoading: true,
		};
	case STOP_WIDGET_BUYING_LOADING:
		return {
			...state,
			isWidgetBuyingLoading: false,
		};	
	default:
		return state;
	}
};

export default usersReducer;
