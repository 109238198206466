import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	fixtureDetailsReady,
	GET_FIXTURE_DETAILS,
	startLoadingFixtureDetails,
	startLoadingVoting,
	stopLoadingFixtureDetails,
	stopLoadingVoting,
	VOTE_FOR_FIXTURE
} from '../actions/fixture-details.actions';
import { isCurrentTimeBetween0000And0005 } from '../../utils/utils';
import { setLoggedUserGameVotesCount } from '../actions/users.actions';
import { setGameData } from '../actions/game.actions';
import { setUnknownUserVotes } from '../../utils/users.utils';

const fetchFixtureById = async (fixtureId, userId) => {
	const query = userId ? `?user=${userId}` : '';
	const response  = await httpProvider.get(`predictions/${fixtureId}${query}`);

	return response;
};

const getFixtureDetailsMiddleware = ({ dispatch, getState }) => next => async action => {
	if (action.type === GET_FIXTURE_DETAILS) {
		dispatch(startLoadingFixtureDetails());

		try {
			const response = await fetchFixtureById(action.payload, getState()?.users?.loggedUser?.id);
  
			if (response.data) {
				const fixtureDetailsState = {
					[`${action.payload}`]: response.data
				};

				dispatch(fixtureDetailsReady(fixtureDetailsState));
			}
		} catch (err) {
			if (err.code === 500) {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			} else {
				toast.error('Такъв мач не съществува!');
			}
		} finally {
			dispatch(stopLoadingFixtureDetails());
		}
	} else {
		return next(action);
	}
};

const voteForFixtureMiddleware = ({ dispatch, getState }) => next => async action => {
	if(action.type === VOTE_FOR_FIXTURE) {
		const cannotVote = isCurrentTimeBetween0000And0005();
		if (cannotVote) {
			toast.info('Моля опитайте да гласувате по-късно.');
			return;
		}

		dispatch(startLoadingVoting());

		try {
			const userId = getState()?.users?.loggedUser?.id;
			const options = userId ? {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			} : {};
			const response  = await httpProvider.patch(`fixtures/votes/${action.payload.fixtureId}`, {
				userId,
				vote: action.payload.vote
			}, options);
  
			if (response.data) {
				if (!userId) {
					setUnknownUserVotes(action.payload.vote, action.payload.fixtureId);
				}

				const newGameVotesCount = response.data.userGameVotesCount;
				const allowedVotes = getState()?.users?.loggedUser?.gameFixtures;

				const fixtureByIdResponse =
					await fetchFixtureById(action.payload.fixtureId, getState()?.users?.loggedUser?.id);

				if (fixtureByIdResponse.data) {
					const fixtureDetailsState = {
						[`${action.payload.fixtureId}`]: fixtureByIdResponse.data
					};

					dispatch(fixtureDetailsReady(fixtureDetailsState));
				}

				if (userId) {
					// TODO: This is not the best way to do it
					// When the user votes for a game, the game data is reset
					// but do we really need this?
					dispatch(setGameData(null));
					dispatch(setLoggedUserGameVotesCount(newGameVotesCount));

					if (newGameVotesCount < allowedVotes) {
						toast.success(`
							Гласувахте успешно! Остават ви ${allowedVotes - newGameVotesCount} прогнози за играта.
						`);
					} else if (newGameVotesCount === allowedVotes) {
						toast.success('Гласувахте успешно! Поздравления изпълнихте всички прогнози за играта!');
					} else if (newGameVotesCount > allowedVotes) {
						toast.success('Гласувахте успешно!');
					}
				} else {
					toast.success('Гласувахте успешно!');
				}
			}
		} catch (err) {
			if (err.response.data.message === 'Вече сте гласували за тази среща!') {
				toast.error(err.response.data.message);
			} else {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			}
		} finally {
			dispatch(stopLoadingVoting());
		}
	} else {
		return next(action);
	}
};

export {
	getFixtureDetailsMiddleware,
	voteForFixtureMiddleware
};