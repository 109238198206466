const GET_LOGGED_USER = 'GET_LOGGED_USER';
const SET_LOGGED_USER = 'SET_LOGGED_USER';
const SET_LOGGED_USER_GAME_VOTES_COUNT = 'SET_LOGGED_USER_GAME_VOTES_COUNT';
const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
const CHANGE_FORGOTTEN_USER_PASSWORD = 'CHANGE_FORGOTTEN_USER_PASSWORD';
const SEND_USER_FORGOTTEN_PASSWORD_EMAIL = 'SEND_USER_FORGOTTEN_PASSWORD_EMAIL';
const DELETE_USER = 'DELETE_USER';
const REGISTER_USER = 'REGISTER_USER';
const START_LOADING_USERS = 'START_LOADING_USERS';
const STOP_LOADING_USERS = 'STOP_LOADING_USERS';
const START_LOGIN_LOADING = 'START_LOGIN_LOADING';
const STOP_LOGIN_LOADING = 'STOP_LOGIN_LOADING';
const TAKE_MISSION_COINS = 'TAKE_MISSION_COINS';
const BUY_WIDGET = 'BUY_WIDGET';
const START_WIDGET_BUYING_LOADING = 'START_WIDGET_BUYING_LOADING';
const STOP_WIDGET_BUYING_LOADING = 'STOP_WIDGET_BUYING_LOADING';

const getLoggedUser = () => ({
	type: GET_LOGGED_USER
});

const deleteUser = (payload) => ({
	type: DELETE_USER,
	payload
});

const changeUserPassword = (payload) => ({
	type: CHANGE_USER_PASSWORD,
	payload
});

const changeForgottenUserPassword = (payload) => ({
	type: CHANGE_FORGOTTEN_USER_PASSWORD,
	payload
});

const sendUserForgottenPasswordEmail = (payload) => ({
	type: SEND_USER_FORGOTTEN_PASSWORD_EMAIL,
	payload
});

const setLoggedUser = (payload) => ({
	type: SET_LOGGED_USER,
	payload
});

const setLoggedUserGameVotesCount = (payload) => ({
	type: SET_LOGGED_USER_GAME_VOTES_COUNT,
	payload
});

const getProfileData = (userId) => ({
	type: GET_PROFILE_DATA,
	payload: userId
});

const setProfileData = (payload) => ({
	type: SET_PROFILE_DATA,
	payload
});

const registerUser = (payload) => ({
	type: REGISTER_USER,
	payload
});

const startLoadingUsers= () => ({
	type: START_LOADING_USERS
});

const stopLoadingUsers = () => ({
	type: STOP_LOADING_USERS
});

const startLoginLoading = () => ({
	type: START_LOGIN_LOADING
});

const stopLoginLoading = () => ({
	type: STOP_LOGIN_LOADING
});

const takeMissionCoins = (payload) => ({
	type: TAKE_MISSION_COINS,
	payload,
});

const buyWidget = (payload) => ({
	type: BUY_WIDGET,
	payload,
});

const startWidgetBuyingLoading = () => ({
	type: START_WIDGET_BUYING_LOADING,
});

const stopWidgetBuyingLoading = () => ({
	type: STOP_WIDGET_BUYING_LOADING,
});

export {
	REGISTER_USER,
	START_LOADING_USERS,
	STOP_LOADING_USERS,
	START_LOGIN_LOADING,
	STOP_LOGIN_LOADING,
	GET_PROFILE_DATA,
	SET_PROFILE_DATA,
	GET_LOGGED_USER,
	SET_LOGGED_USER,
	DELETE_USER,
	CHANGE_USER_PASSWORD,
	CHANGE_FORGOTTEN_USER_PASSWORD,
	SEND_USER_FORGOTTEN_PASSWORD_EMAIL,
	SET_LOGGED_USER_GAME_VOTES_COUNT,
	TAKE_MISSION_COINS,
	BUY_WIDGET,
	START_WIDGET_BUYING_LOADING,
	STOP_WIDGET_BUYING_LOADING,
	changeUserPassword,
	changeForgottenUserPassword,
	sendUserForgottenPasswordEmail,
	registerUser,
	startLoadingUsers,
	stopLoadingUsers,
	startLoginLoading,
	stopLoginLoading,
	getProfileData,
	setProfileData,
	getLoggedUser,
	setLoggedUser,
	deleteUser,
	setLoggedUserGameVotesCount,
	takeMissionCoins,
	buyWidget,
	startWidgetBuyingLoading,
	stopWidgetBuyingLoading,
};