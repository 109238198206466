import { format } from 'date-fns';
import { level10, level1, level2, level3, level4, level5, level6, level7, level8, level9 } from '../common/users.const';

export const transformBalance = (balance) => {
	if (balance > 0) {
		return `+${balance}`;
	}

	return balance;
};

export const getLevel = (value, type) => {
	const levels = [
		level1(value, type),
		level2(value, type),
		level3(value, type),
		level4(value, type),
		level5(value, type),
		level6(value, type),
		level7(value, type),
		level8(value, type),
		level9(value, type),
		level10(value, type),
	];
	let current = 0;

	for (let index = levels.length - 1; index >= 0; index--) {
		if (value >= levels[index].types[type].min && value < levels[index].types[type].max) {
			current = index;
			break;
		}
	}

	return levels[current];
};

export const getAchievements = (data, period, hasAdditionalAchievements) => {
	const achievements = Object.entries(data.badges.voting[period]).slice(0, 4).filter(([, value]) => value > 0);

	if (achievements.length === 0 && !hasAdditionalAchievements) {
		return [['noAchievements', 0]];
	}

	return achievements;
};

export const getUserPositionChangeKey = (positions) => {
	if (!positions) {
		return 'neutral';
	}

	if (positions[1] < positions[0]) {
		return 'up';
	} else if (positions[1] > positions[0]) {
		return 'down';
	}

	return 'neutral';
};

export const setUnknownUserVotes = (vote, fixtureId) => {
	const votesStr = localStorage.getItem('votes');
	const date = format(new Date(), 'yyyy-MM-dd');
	const newVote = {
		fixtureId,
		vote
	};

	if (!votesStr) {
		localStorage.setItem('votes', JSON.stringify([
			{
				date,
				votes: [newVote]
			}
		]));
	} else {
		let votesArr = JSON.parse(votesStr);
		const voteByDate = votesArr.find(vote => vote.date === date);

		// remove old votes
		votesArr = votesArr.filter(vote => vote.date === date);

		if (!voteByDate) {
			votesArr.push({
				date,
				votes: [newVote]
			});
		} else {
			voteByDate.votes.push(newVote);
		}

		localStorage.setItem('votes', JSON.stringify(votesArr));
	}
};

export const getUnkownUserVote = (fixtureId) => {
	const votesStr = localStorage.getItem('votes');
	if (!votesStr) {
		return null;
	} else {
		const votesArr = JSON.parse(votesStr);
		const date = format(new Date(), 'yyyy-MM-dd');
		const voteByDate = votesArr.find(vote => vote.date === date);
		const vote = voteByDate?.votes?.find(vote => vote.fixtureId === `${fixtureId}`);

		return vote?.vote;
	}
};