import {
	SET_GAME_DATA,
	SET_USER_MISSIONS,
	START_LOADING_GAME_DATA,
	STOP_LOADING_GAME_DATA
} from '../actions/game.actions';

const initialState = {
	isLoading: false,
	data: null,
};

const gameReducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_GAME_DATA:
		return {
			...state,
			data: action.payload
		};
	case START_LOADING_GAME_DATA:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_GAME_DATA:
		return {
			...state,
			isLoading: false
		};
	case SET_USER_MISSIONS:
		return {
			...state,
			data: {
				...state.data,
				missions: {
					...state.data.missions,
					[action.payload.mission]: {
						...state.data.missions[action.payload.mission],
						taken: true
					}
				}
			},
		};
	default:
		return state;
	}
};

export default gameReducer;