import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	teamDataReady,
	GET_TEAM,
	stopLoadingTeam,
	startLoadingTeam,
} from '../actions/teams.actions';

const getTeamMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_TEAM) {
		dispatch(startLoadingTeam());

		try {
			const response  = await httpProvider.get(`teams/${action.payload}`);

			const teamsState = {
				[`${action.payload}`]: response.data
			};
  
			if (response.data) {
				dispatch(teamDataReady(teamsState));
			}
		} catch (err) {
			if (err.code === 500) {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			} else {
				toast.error('Няма данни за този отбор!');
			}
		} finally {
			dispatch(stopLoadingTeam());
		}
	} else {
		return next(action);
	}
};

export {
	getTeamMiddleware,
};