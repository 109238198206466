/* eslint-disable max-len */
import React from 'react';
import { months } from '../utils/utils';

import euroBadgeLogo from '../assets/euroBadgeLogo.svg';

export const levelColors = {
	0: '#424242',
	1: 'linear-gradient(0deg, #BABABA 0%, #FFF 100%)',
	2: 'linear-gradient(0deg, #8E401B 0%, #C76C3F 100%)',
	3: 'linear-gradient(0deg, #A57211 0%, #F8F05D 100%)',
	4: 'linear-gradient(0deg, #0C8BFE 0%, #6DB9FF 100%)',
	5: 'linear-gradient(0deg, #C56B00 0%, #FFAD4F 100%)',
	6: 'linear-gradient(0deg, #773AD8 0%, #BC93FE 100%)',
	7: 'linear-gradient(0deg, #07733E 0%, #5DE179 100%)',
	8: 'linear-gradient(0deg, #B40019 0%, #FF4F66 100%)',
	9: 'linear-gradient(0deg, #183FA5 0%, #0085FF 100%)'
};

export const flatLevelColors = {
	0: '#424242',
	1: '#DBDBDB',
	2: '#C3693D',
	3: '#EADB50',
	4: '#61B3FF',
	5: '#F4A140',
	6: '#A576F1',
	7: '#48C76B',
	8: '#F6455D',
	9: '#0576EB',
};

const info = {
	all: {
		label: 'Всички прогнози',
		text: 'Общ брой направени прогнози',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
				<path d="M8.61329 0.617188C7.03103 0.617188 5.48432 1.08638 4.16872 1.96543C2.85313 2.84448 1.82775 4.09391 1.22225 5.55572C0.616747 7.01753 0.458321 8.62606 0.767003 10.1779C1.07568 11.7298 1.83761 13.1552 2.95643 14.274C4.07525 15.3929 5.50071 16.1548 7.05256 16.4635C8.60441 16.7722 10.2129 16.6137 11.6748 16.0082C13.1366 15.4027 14.386 14.3773 15.265 13.0617C16.1441 11.7462 16.6133 10.1994 16.6133 8.61719C16.6133 6.49546 15.7704 4.46062 14.2701 2.96033C12.7698 1.46004 10.735 0.617188 8.61329 0.617188ZM4.61329 11.5572H3.46328V6.84719H2.28328V5.66719H4.61329V11.5572ZM9.28329 11.5572L8.13329 9.61719L6.95329 11.6172H5.61329L7.45329 8.43719L5.77328 5.66719H7.16329L8.16329 7.28719L9.16329 5.66719H10.4933L8.84328 8.43719L10.6933 11.5572H9.28329ZM14.9133 10.3872V11.5572H11.4033V9.20719C11.4014 9.05073 11.4315 8.89553 11.4917 8.7511C11.5518 8.60666 11.6409 8.47603 11.7533 8.36719C11.8645 8.25307 11.9987 8.16378 12.1469 8.10518C12.2951 8.04659 12.4541 8.02001 12.6133 8.02719H13.7933V6.84719H11.4033V5.66719H13.7633C13.9191 5.66343 14.074 5.69166 14.2185 5.75014C14.363 5.80862 14.494 5.8961 14.6033 6.00719C14.7139 6.11687 14.8011 6.24789 14.8595 6.39229C14.918 6.53668 14.9465 6.69144 14.9433 6.84719V8.02719C14.9463 8.18142 14.9176 8.33462 14.8592 8.47737C14.8007 8.62011 14.7136 8.74937 14.6033 8.85719C14.4944 8.9696 14.3638 9.05863 14.2194 9.11881C14.0749 9.17899 13.9197 9.20906 13.7633 9.20719H12.6133V10.3872H14.9133Z" fill="black"/>
			</svg>
		)
	},
	quizes: {
		label: 'Решени Куизове',
		text: 'Общ брой правилни отговори',
		icon: (
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 9.41003C0 9.16039 0.0887216 8.92097 0.246647 8.74445C0.404572 8.56792 0.618765 8.46875 0.842105 8.46875H7.57895V15.999H0.842105C0.618765 15.999 0.404572 15.8999 0.246647 15.7233C0.0887216 15.5468 0 15.3074 0 15.0577V9.41003Z" fill="black"/>
				<path d="M0.842105 0C0.618765 0 0.404572 0.0991708 0.246647 0.275696C0.0887216 0.452221 0 0.69164 0 0.941285L0 6.58899C0 6.83864 0.0887216 7.07806 0.246647 7.25458C0.404572 7.43111 0.618765 7.53028 0.842105 7.53028H7.57895V0H0.842105ZM3.08211 5.64771L1.68421 4.14165L2.38316 3.38862L3.08211 4.14165L5.18737 1.88257L5.89474 2.6356L3.08211 5.64771Z" fill="black"/>
				<path d="M16.0008 15.0577C16.0008 15.3074 15.9121 15.5468 15.7542 15.7233C15.5963 15.8999 15.3821 15.999 15.1587 15.999H8.42188V8.46875H15.1587C15.3821 8.46875 15.5963 8.56792 15.7542 8.74445C15.9121 8.92097 16.0008 9.16039 16.0008 9.41003V15.0577Z" fill="black"/>
				<path d="M16.0008 6.58899C16.0008 6.83864 15.9121 7.07806 15.7542 7.25458C15.5963 7.43111 15.3821 7.53028 15.1587 7.53028H8.42188V0H15.1587C15.3821 0 15.5963 0.0991708 15.7542 0.275696C15.9121 0.452221 16.0008 0.69164 16.0008 0.941285V6.58899Z" fill="black"/>
			</svg>
		)
	},
	right: {
		label: 'Всички познати',
		text: 'Общ брой познати прогнози',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
				<path d="M8.1582 0.320312C3.7422 0.320312 0.158203 3.90431 0.158203 8.32031C0.158203 12.7363 3.7422 16.3203 8.1582 16.3203C12.5742 16.3203 16.1582 12.7363 16.1582 8.32031C16.1582 3.90431 12.5742 0.320312 8.1582 0.320312ZM6.5582 12.3203L2.5582 8.32031L3.6862 7.19231L6.5582 10.0563L12.6302 3.98431L13.7582 5.12031L6.5582 12.3203Z" fill="black"/>
			</svg>
		)
	},
	winCoef: {
		label: 'Коеф. на познати',
		text: 'Общ коефициент на всички познати прогнози',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
				<path d="M15.6515 4.15149L14.6043 5.72655C15.1093 6.73381 15.3565 7.85062 15.3239 8.97691C15.2912 10.1032 14.9796 11.2038 14.417 12.1801H2.61674C1.88554 10.9116 1.58256 9.44126 1.7527 7.98704C1.92285 6.53283 2.55707 5.17215 3.56134 4.10673C4.56561 3.0413 5.88647 2.32785 7.3281 2.07214C8.76973 1.81643 10.2554 2.03207 11.5648 2.6871L13.1399 1.63989C11.5363 0.611602 9.63415 0.150821 7.73774 0.331257C5.84133 0.511693 4.06022 1.32292 2.67931 2.63518C1.2984 3.94743 0.397483 5.6849 0.120659 7.56965C-0.156164 9.4544 0.207103 11.3775 1.15236 13.0315C1.30093 13.2888 1.51427 13.5028 1.77117 13.6522C2.02807 13.8015 2.31958 13.881 2.61674 13.8828H14.4085C14.7085 13.884 15.0035 13.8059 15.2637 13.6564C15.5238 13.5069 15.7398 13.2913 15.8899 13.0315C16.6743 11.6726 17.068 10.1233 17.0274 8.55482C16.9869 6.9863 16.5136 5.45949 15.66 4.14297L15.6515 4.15149ZM7.3164 9.97497C7.47454 10.1333 7.66234 10.2589 7.86905 10.3446C8.07577 10.4303 8.29734 10.4744 8.52111 10.4744C8.74488 10.4744 8.96646 10.4303 9.17317 10.3446C9.37989 10.2589 9.56768 10.1333 9.72583 9.97497L14.5447 2.7467L7.3164 7.56555C7.15808 7.72369 7.03249 7.91149 6.9468 8.1182C6.86111 8.32491 6.817 8.54649 6.817 8.77026C6.817 8.99403 6.86111 9.21561 6.9468 9.42232C7.03249 9.62904 7.15808 9.81683 7.3164 9.97497Z" fill="black"/>
			</svg>
		)
	},
	createdOn: {
		label: 'Време от регистрация',
		text: 'Времето изминало от деня на присъединяването',
		icon: (
			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
				<path d="M7.4562 8.69463L6.75396 7.64126C6.68511 7.53111 6.58873 7.44161 6.4648 7.37276C6.34088 7.30391 6.21007 7.26949 6.07237 7.26949H0.660984C0.50952 6.91148 0.406249 6.56725 0.351171 6.23678C0.296094 5.90631 0.268555 5.56208 0.268555 5.20407C0.268555 3.90974 0.702292 2.82884 1.56977 1.96137C2.43724 1.09389 3.51814 0.660156 4.81247 0.660156C5.52848 0.660156 6.21007 0.81162 6.85723 1.11455C7.5044 1.41748 8.06206 1.84433 8.53022 2.39511C8.99838 1.84433 9.55604 1.41748 10.2032 1.11455C10.8504 0.81162 11.532 0.660156 12.248 0.660156C13.5423 0.660156 14.6232 1.09389 15.4907 1.96137C16.3581 2.82884 16.7919 3.90974 16.7919 5.20407C16.7919 5.56208 16.7643 5.90631 16.7093 6.23678C16.6542 6.56725 16.5509 6.91148 16.3995 7.26949H11.4838L10.0586 5.16276C9.97601 5.03884 9.8693 4.9459 9.73849 4.88393C9.60768 4.82197 9.46654 4.79099 9.31508 4.79099C9.13607 4.79099 8.98117 4.84262 8.85036 4.9459C8.71955 5.04917 8.62661 5.18342 8.57153 5.34865L7.4562 8.69463ZM8.53022 15.8203L7.33228 14.7463C5.88649 13.452 4.70231 12.3435 3.77976 11.421C2.85721 10.4984 2.13431 9.66537 1.61108 8.92182H5.57667L7.00181 11.0285C7.08443 11.1525 7.19114 11.2454 7.32195 11.3074C7.45276 11.3693 7.5939 11.4003 7.74536 11.4003C7.92436 11.4003 8.07927 11.3487 8.21008 11.2454C8.34089 11.1421 8.43383 11.0079 8.48891 10.8427L9.60424 7.47603L10.3271 8.55005C10.396 8.6602 10.4924 8.7497 10.6163 8.81855C10.7402 8.8874 10.871 8.92182 11.0087 8.92182H15.4494C14.9261 9.66537 14.2032 10.4984 13.2807 11.421C12.3581 12.3435 11.174 13.452 9.72816 14.7463L8.53022 15.8203Z" fill="black"/>
			</svg>
		)
	}
};

const levelTemplate = (value, type, backgroundColor, levelLabel, ranges) => {
	const calculateProgress = (value, range) => (value / range.max) * 100;

	const types = Object.fromEntries(
		Object.entries(ranges).map(([key, range]) => [
			key, { ...range, progress: calculateProgress(value, range) }
		])
	);

	return {
		style: { background: backgroundColor },
		progress: calculateProgress(value, ranges[type]),
		types,
		info: info[type],
		level: {
			style: { background: flatLevelColors[levelLabel - 1] },
			label: levelLabel,
		}
	};
};

export const level1 = (value, type) => levelTemplate(value, type, levelColors[0], 1, {
	all: { min: 0, max: 25 },
	right: { min: 0, max: 12 },
	winCoef: { min: 0, max: 25 },
	createdOn: { min: 0, max: 7 },
	quizes: { min: 0, max: 10 }
});

export const level2 = (value, type) => levelTemplate(value, type, levelColors[1], 2, {
	all: { min: 25, max: 50 },
	right: { min: 12, max: 25 },
	winCoef: { min: 25, max: 50 },
	createdOn: { min: 7, max: 14 },
	quizes: { min: 10, max: 30 }
});

export const level3 = (value, type) => levelTemplate(value, type, levelColors[2], 3, {
	all: { min: 50, max: 100 },
	right: { min: 25, max: 50 },
	winCoef: { min: 50, max: 100 },
	createdOn: { min: 14, max: 30 },
	quizes: { min: 30, max: 60 }
});

export const  level4 = (value, type) => levelTemplate(value, type, levelColors[3], 4, {
	all: { min: 100, max: 250 },
	right: { min: 50, max: 125 },
	winCoef: { min: 100, max: 250 },
	createdOn: { min: 30, max: 60 },
	quizes: { min: 60, max: 120 }
});

export const level5 = (value, type) => levelTemplate(value, type, levelColors[4], 5, {
	all: { min: 250, max: 700 },
	right: { min: 125, max: 350 },
	winCoef: { min: 250, max: 700 },
	createdOn: { min: 60, max: 180 },
	quizes: { min: 120, max: 240 }
});

export const level6 = (value, type) => levelTemplate(value, type, levelColors[5], 6, {
	all: { min: 700, max: 1500 },
	right: { min: 350, max: 750 },
	winCoef: { min: 700, max: 1500 },
	createdOn: { min: 180, max: 360 },
	quizes: { min: 240, max: 480 }
});

export const level7 = (value, type) => levelTemplate(value, type, levelColors[6], 7, {
	all: { min: 1500, max: 3000 },
	right: { min: 750, max: 1500 },
	winCoef: { min: 1500, max: 3000 },
	createdOn: { min: 360, max: 720 },
	quizes: { min: 480, max: 700 }
});

export const level8 = (value, type) => levelTemplate(value, type, levelColors[7], 8, {
	all: { min: 3000, max: 5000 },
	right: { min: 1500, max: 2500 },
	winCoef: { min: 3000, max: 5000 },
	createdOn: { min: 720, max: 1080 },
	quizes: { min: 700, max: 1200 }
});

export const level9 = (value, type) => levelTemplate(value, type, levelColors[8], 9, {
	all: { min: 5000, max: 10000 },
	right: { min: 2500, max: 5000 },
	winCoef: { min: 5000, max: 10000 },
	createdOn: { min: 1080, max: 1460 },
	quizes: { min: 1200, max: 1600 }
});

// next level
export const level10 = (value, type) => levelTemplate(value, type, levelColors[9], 10, {
	all: { min: 10000, max: 10000000 }, //
	right: { min: 5000, max: 10000000 }, //
	winCoef: { min: 10000, max: 10000000 }, //
	createdOn: { min: 1460, max: 10000000 },
	quizes: { min: 1600, max: 10000000 }
});

export const getAchievementType = (key, isOwner) => {
	const achievementsMap = {
		first: {
			label: 'Шампион',
			text: 'Завършил на първо място',
			icon: (
				<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.213 4.1786H23.8156V0.78125H6.82888V4.1786H3.43153C1.56299 4.1786 0.0341797 5.70741 0.0341797 7.57595V9.27463C0.0341797 13.6063 3.29564 17.1395 7.49137 17.6661C8.56153 20.2141 10.8547 22.1336 13.6236 22.6942V27.9601H6.82888V31.3574H23.8156V27.9601H17.0209V22.6942C19.7898 22.1336 22.083 20.2141 23.1532 17.6661C27.3489 17.1395 30.6104 13.6063 30.6104 9.27463V7.57595C30.6104 5.70741 29.0815 4.1786 27.213 4.1786ZM3.43153 9.27463V7.57595H6.82888V14.0649C4.85842 13.3515 3.43153 11.4829 3.43153 9.27463ZM27.213 9.27463C27.213 11.4829 25.7861 13.3515 23.8156 14.0649V7.57595H27.213V9.27463Z" fill="black"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(255,217,90,1) 0%, rgba(249,196,22,1) 100%)'
		},
		second: {
			label: 'Сребърен медал',
			text: 'Завършил на второ място',
			icon: (
				<svg width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.63737 9.71691C10.2774 8.11691 11.5574 7.02891 13.9414 7.02891C16.5174 7.02891 17.9894 8.74091 17.9894 10.6929C17.9894 13.0929 16.8694 14.0369 13.7174 15.2689C13.1254 15.4929 12.2454 15.6689 12.2454 16.1489V16.2769H17.1094C17.6054 16.2769 17.7654 16.4529 17.7654 16.9329V17.9729C17.7654 18.4689 17.6054 18.6289 17.1094 18.6289H10.1814C9.70137 18.6289 9.54137 18.4689 9.54137 17.9729V16.7569C9.54137 14.5649 11.1254 13.8129 12.6134 13.1889C13.9894 12.6449 15.3014 12.1809 15.3014 10.8049C15.3014 10.0209 14.7254 9.41291 13.8454 9.41291C12.9654 9.41291 12.4534 9.78091 12.1014 10.5809C11.8774 11.0609 11.7174 11.1569 11.2694 10.9969L10.0374 10.5489C9.58937 10.3889 9.46137 10.1649 9.63737 9.71691Z" fill="black"/>
					<path d="M4.17236 34.6286V22.1C3.1452 20.9647 2.34781 19.6673 1.78017 18.2076C1.21253 16.748 0.928711 15.1938 0.928711 13.5449C0.928711 9.92283 2.18562 6.85488 4.69945 4.34105C7.21328 1.82723 10.2812 0.570312 13.9033 0.570312C17.5254 0.570312 20.5933 1.82723 23.1071 4.34105C25.621 6.85488 26.8779 9.92283 26.8779 13.5449C26.8779 15.1938 26.5941 16.748 26.0264 18.2076C25.4588 19.6673 24.6614 20.9647 23.6342 22.1V34.6286L13.9033 31.385L4.17236 34.6286ZM13.9033 23.2758C16.6063 23.2758 18.9039 22.3298 20.796 20.4376C22.6882 18.5455 23.6342 16.2479 23.6342 13.5449C23.6342 10.8419 22.6882 8.54428 20.796 6.65215C18.9039 4.76002 16.6063 3.81396 13.9033 3.81396C11.2003 3.81396 8.90268 4.76002 7.01055 6.65215C5.11842 8.54428 4.17236 10.8419 4.17236 13.5449C4.17236 16.2479 5.11842 18.5455 7.01055 20.4376C8.90268 22.3298 11.2003 23.2758 13.9033 23.2758ZM7.416 29.8037L13.9033 28.1413L20.3906 29.8037V24.776C19.4445 25.3166 18.4241 25.7424 17.3294 26.0532C16.2347 26.3641 15.0926 26.5195 13.9033 26.5195C12.714 26.5195 11.5719 26.3641 10.4772 26.0532C9.38246 25.7424 8.36207 25.3166 7.416 24.776V29.8037Z" fill="black"/>
					<rect x="5.86523" y="24.1875" width="16.2822" height="6.33203" fill="black"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(193,193,193,1) 0%, rgba(163,163,163,1) 100%)'
		},
		third: {
			label: 'Бронзов медал',
			text: 'Завършил на трето място',
			icon: (
				<svg width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M17.8774 10.473C17.8774 11.513 17.4774 12.329 16.6774 12.841C17.7654 13.401 18.2294 14.393 18.2294 15.513C18.2294 17.625 16.8534 19.033 13.9894 19.033C11.5254 19.033 10.1334 18.073 9.49337 16.345C9.33337 15.897 9.44537 15.673 9.89337 15.513L11.0934 15.081C11.5414 14.921 11.7334 15.017 11.9254 15.497C12.2294 16.281 12.6774 16.681 13.9094 16.681C15.0134 16.681 15.5094 16.217 15.5094 15.433C15.5094 14.569 15.0614 14.185 14.1654 14.185H12.9014C12.4054 14.185 12.2454 14.025 12.2454 13.545V12.553C12.2454 12.057 12.4054 11.897 12.9014 11.897H13.8454C14.6454 11.897 15.2534 11.529 15.2534 10.729C15.2534 9.92897 14.7734 9.54497 13.8454 9.54497C12.6774 9.54497 12.3094 9.94497 12.0054 10.729C11.8134 11.209 11.6374 11.273 11.1734 11.145L9.97337 10.713C9.52537 10.553 9.41337 10.329 9.57337 9.88097C10.2134 8.15297 11.5254 7.19297 13.9894 7.19297C16.4694 7.19297 17.8774 8.45697 17.8774 10.473Z" fill="black"/>
					<path d="M4.17236 34.7927V22.2641C3.1452 21.1288 2.34781 19.8313 1.78017 18.3717C1.21253 16.9121 0.928711 15.3578 0.928711 13.709C0.928711 10.0869 2.18562 7.01894 4.69945 4.50511C7.21328 1.99129 10.2812 0.734375 13.9033 0.734375C17.5254 0.734375 20.5933 1.99129 23.1071 4.50511C25.621 7.01894 26.8779 10.0869 26.8779 13.709C26.8779 15.3578 26.5941 16.9121 26.0264 18.3717C25.4588 19.8313 24.6614 21.1288 23.6342 22.2641V34.7927L13.9033 31.549L4.17236 34.7927ZM13.9033 23.4399C16.6063 23.4399 18.9039 22.4938 20.796 20.6017C22.6882 18.7096 23.6342 16.412 23.6342 13.709C23.6342 11.0059 22.6882 8.70834 20.796 6.81621C18.9039 4.92409 16.6063 3.97802 13.9033 3.97802C11.2003 3.97802 8.90268 4.92409 7.01055 6.81621C5.11842 8.70834 4.17236 11.0059 4.17236 13.709C4.17236 16.412 5.11842 18.7096 7.01055 20.6017C8.90268 22.4938 11.2003 23.4399 13.9033 23.4399ZM7.416 29.9677L13.9033 28.3054L20.3906 29.9677V24.9401C19.4445 25.4807 18.4241 25.9064 17.3294 26.2173C16.2347 26.5281 15.0926 26.6835 13.9033 26.6835C12.714 26.6835 11.5719 26.5281 10.4772 26.2173C9.38246 25.9064 8.36207 25.4807 7.416 24.9401V29.9677Z" fill="black"/>
					<rect x="5.86523" y="24.957" width="16.2822" height="6.33203" fill="black"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(231,150,71,1) 0%, rgba(199,123,48,1) 100%)'
		},
		top10: {
			label: 'Топ 10',
			text: 'Завършил между 4 - 10 място',
			icon: (
				<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.6078 36.0522L10.4116 30.6692L4.35581 29.3235L4.94457 23.0995L0.823242 18.3894L4.94457 13.6793L4.35581 7.45526L10.4116 6.10952L13.6078 0.726562L19.3271 3.16571L25.0465 0.726562L28.2427 6.10952L34.2985 7.45526L33.7097 13.6793L37.8311 18.3894L33.7097 23.0995L34.2985 29.3235L28.2427 30.6692L25.0465 36.0522L19.3271 33.613L13.6078 36.0522Z" fill="black"/>
					<path d="M16.1352 12.5306C16.6152 12.5306 16.7912 12.6906 16.7912 13.1866V23.2346C16.7912 23.7306 16.6152 23.8906 16.1352 23.8906H14.8392C14.3592 23.8906 14.1832 23.7306 14.1832 23.2346V15.7306L12.6312 16.6106C12.1352 16.8986 11.8632 16.7866 11.6392 16.3546L11.0792 15.3466C10.8552 14.9306 10.9672 14.7226 11.3992 14.4666L14.2952 12.7866C14.6472 12.5786 14.8232 12.5306 15.2232 12.5306H16.1352ZM17.8534 16.2746C17.8534 13.8426 19.4534 12.2906 22.2374 12.2906C25.0054 12.2906 26.5894 13.8426 26.5894 16.2746V20.1146C26.5894 22.5786 25.0054 24.1306 22.2374 24.1306C19.4534 24.1306 17.8534 22.5786 17.8534 20.1146V16.2746ZM23.9974 16.2426C23.9974 15.2186 23.3414 14.6746 22.2374 14.6746C21.1014 14.6746 20.4454 15.2186 20.4454 16.2426V20.1626C20.4454 21.1866 21.1014 21.7466 22.2374 21.7466C23.3414 21.7466 23.9974 21.1866 23.9974 20.1626V16.2426Z" fill="#F5AA70"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(255,180,123,1) 0%, rgba(232,156,98,1) 100%'
		},
		top50: {
			label: 'Топ 50',
			text: 'Завършил между 11 - 50 място',
			icon: (
				<svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.6078 35.5991L10.4116 30.2161L4.35581 28.8704L4.94457 22.6463L0.823242 17.9363L4.94457 13.2262L4.35581 7.00213L10.4116 5.65639L13.6078 0.273438L19.3271 2.71259L25.0465 0.273438L28.2427 5.65639L34.2985 7.00213L33.7097 13.2262L37.8311 17.9363L33.7097 22.6463L34.2985 28.8704L28.2427 30.2161L25.0465 35.5991L19.3271 33.1599L13.6078 35.5991Z" fill="black"/>
					<path d="M17.3392 12.0775C17.8352 12.0775 17.9952 12.2375 17.9952 12.7335V13.7895C17.9952 14.2855 17.8352 14.4455 17.3392 14.4455H13.1312L12.9712 16.1255H14.4432C17.2112 16.1255 18.4592 17.7895 18.4592 19.8375C18.4592 21.9335 16.9712 23.6775 14.3152 23.6775C11.8352 23.6775 10.4752 22.7655 9.83524 21.0375C9.67524 20.5735 9.78724 20.3655 10.2352 20.2055L11.4672 19.7575C11.9152 19.5975 12.1072 19.6775 12.2992 20.1575C12.5872 20.8935 13.1152 21.2935 14.2512 21.2935C15.1152 21.2935 15.7392 20.7975 15.7392 19.7895C15.7392 18.9095 15.1152 18.4295 14.2352 18.4295H10.8912C10.3312 18.4295 10.1712 18.1895 10.2192 17.6775L10.6992 13.0055C10.7632 12.3015 11.0192 12.0775 11.7392 12.0775H17.3392ZM18.9315 15.8215C18.9315 13.3895 20.5315 11.8375 23.3155 11.8375C26.0835 11.8375 27.6675 13.3895 27.6675 15.8215V19.6615C27.6675 22.1255 26.0835 23.6775 23.3155 23.6775C20.5315 23.6775 18.9315 22.1255 18.9315 19.6615V15.8215ZM25.0755 15.7895C25.0755 14.7655 24.4195 14.2215 23.3155 14.2215C22.1795 14.2215 21.5235 14.7655 21.5235 15.7895V19.7095C21.5235 20.7335 22.1795 21.2935 23.3155 21.2935C24.4195 21.2935 25.0755 20.7335 25.0755 19.7095V15.7895Z" fill="#D3CF8C"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(220,208,144,1) 0%, rgba(196,206,134,1) 100%)'
		},
		top100: {
			label: 'Топ 100',
			text: 'Завършил между 51 - 100 място',
			icon: (
				<svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.6078 35.7983L10.4116 30.4153L4.35581 29.0696L4.94457 22.8456L0.823242 18.1355L4.94457 13.4254L4.35581 7.20135L10.4116 5.85561L13.6078 0.472656L19.3271 2.91181L25.0465 0.472656L28.2427 5.85561L34.2985 7.20135L33.7097 13.4254L37.8311 18.1355L33.7097 22.8456L34.2985 29.0696L28.2427 30.4153L25.0465 35.7983L19.3271 33.3591L13.6078 35.7983Z" fill="black"/>
					<path d="M11.6352 12.2767C12.1152 12.2767 12.2912 12.4367 12.2912 12.9327V22.9807C12.2912 23.4767 12.1152 23.6367 11.6352 23.6367H10.3392C9.85924 23.6367 9.68324 23.4767 9.68324 22.9807V15.4767L8.13124 16.3567C7.63524 16.6447 7.36324 16.5327 7.13924 16.1007L6.57924 15.0927C6.35524 14.6767 6.46724 14.4687 6.89924 14.2127L9.79524 12.5327C10.1472 12.3247 10.3232 12.2767 10.7232 12.2767H11.6352ZM13.3534 16.0207C13.3534 13.5887 14.9534 12.0367 17.7374 12.0367C20.5054 12.0367 22.0894 13.5887 22.0894 16.0207V19.8607C22.0894 22.3247 20.5054 23.8767 17.7374 23.8767C14.9534 23.8767 13.3534 22.3247 13.3534 19.8607V16.0207ZM19.4974 15.9887C19.4974 14.9647 18.8414 14.4207 17.7374 14.4207C16.6014 14.4207 15.9454 14.9647 15.9454 15.9887V19.9087C15.9454 20.9327 16.6014 21.4927 17.7374 21.4927C18.8414 21.4927 19.4974 20.9327 19.4974 19.9087V15.9887ZM22.7877 16.0207C22.7877 13.5887 24.3877 12.0367 27.1717 12.0367C29.9397 12.0367 31.5237 13.5887 31.5237 16.0207V19.8607C31.5237 22.3247 29.9397 23.8767 27.1717 23.8767C24.3877 23.8767 22.7877 22.3247 22.7877 19.8607V16.0207ZM28.9317 15.9887C28.9317 14.9647 28.2757 14.4207 27.1717 14.4207C26.0357 14.4207 25.3797 14.9647 25.3797 15.9887V19.9087C25.3797 20.9327 26.0357 21.4927 27.1717 21.4927C28.2757 21.4927 28.9317 20.9327 28.9317 19.9087V15.9887Z" fill="#B7D0B0"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(189,215,182,1) 0%, rgba(171,194,164,1) 100%)'
		},
		noAchievements: {
			label: isOwner ? 'Нямате постижения' : 'Няма постижения',
			text: isOwner ? 'Гласувайте за изход на мачове, за да достигнете постижения' : 'Играчът все още не е достигнал постижения',
			icon: (
				<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M27.246 16.7393C25.0638 16.7393 19.1738 16.7393 19.1738 16.7393V8.61182C19.1738 4.24291 22.907 0.63672 27.2736 0.63672C31.6356 0.634416 35.175 4.1807 35.175 8.55191C35.175 12.9231 31.608 16.7393 27.246 16.7393Z" fill="#000000"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M16.6692 16.7968C16.6692 16.7968 10.825 16.7968 8.55991 16.7968C4.19331 16.7968 0.65625 12.9238 0.65625 8.5503C0.65625 4.1814 4.19331 0.632812 8.55991 0.632812C12.9242 0.635117 16.6692 4.17909 16.6692 8.5503V16.7968Z" fill="#000000"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M16.8346 27.2665C16.8346 31.6377 12.8185 35.184 8.51411 35.184C4.20973 35.184 0.71875 31.64 0.71875 27.2665C0.71875 22.893 4.15212 19.082 8.4565 19.082C10.6594 19.082 16.8346 19.1396 16.8346 19.1396V27.2665Z" fill="#000000"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M27.2647 35.1844C22.9165 35.1844 19.1699 31.6173 19.1699 27.2438C19.1699 25.0709 19.216 19.0742 19.216 19.0742H27.2647C31.6105 19.0742 35.1361 22.8934 35.1361 27.2669C35.1361 31.6381 31.6105 35.1844 27.2647 35.1844Z" fill="#000000"/>
				</svg>

			),
			background: '#686868'
		},
		euroFirst: {
			label: 'Шампион',
			text: 'Завършил на първо място',
			icon: (
				<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27.213 4.1786H23.8156V0.78125H6.82888V4.1786H3.43153C1.56299 4.1786 0.0341797 5.70741 0.0341797 7.57595V9.27463C0.0341797 13.6063 3.29564 17.1395 7.49137 17.6661C8.56153 20.2141 10.8547 22.1336 13.6236 22.6942V27.9601H6.82888V31.3574H23.8156V27.9601H17.0209V22.6942C19.7898 22.1336 22.083 20.2141 23.1532 17.6661C27.3489 17.1395 30.6104 13.6063 30.6104 9.27463V7.57595C30.6104 5.70741 29.0815 4.1786 27.213 4.1786ZM3.43153 9.27463V7.57595H6.82888V14.0649C4.85842 13.3515 3.43153 11.4829 3.43153 9.27463ZM27.213 9.27463C27.213 11.4829 25.7861 13.3515 23.8156 14.0649V7.57595H27.213V9.27463Z" fill="#FCCE34"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(48,87,194,1) 0%, rgba(41,72,157,1) 100%)',
			additionalIcon: euroBadgeLogo,
			color: '#FFFFFF'
		},
		euroSecond: {
			label: 'Сребърен медал',
			text: 'Завършил на второ място',
			icon: (
				<svg width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.63737 9.71691C10.2774 8.11691 11.5574 7.02891 13.9414 7.02891C16.5174 7.02891 17.9894 8.74091 17.9894 10.6929C17.9894 13.0929 16.8694 14.0369 13.7174 15.2689C13.1254 15.4929 12.2454 15.6689 12.2454 16.1489V16.2769H17.1094C17.6054 16.2769 17.7654 16.4529 17.7654 16.9329V17.9729C17.7654 18.4689 17.6054 18.6289 17.1094 18.6289H10.1814C9.70137 18.6289 9.54137 18.4689 9.54137 17.9729V16.7569C9.54137 14.5649 11.1254 13.8129 12.6134 13.1889C13.9894 12.6449 15.3014 12.1809 15.3014 10.8049C15.3014 10.0209 14.7254 9.41291 13.8454 9.41291C12.9654 9.41291 12.4534 9.78091 12.1014 10.5809C11.8774 11.0609 11.7174 11.1569 11.2694 10.9969L10.0374 10.5489C9.58937 10.3889 9.46137 10.1649 9.63737 9.71691Z" fill="#A3A3A3"/>
					<path d="M4.17236 34.6286V22.1C3.1452 20.9647 2.34781 19.6673 1.78017 18.2076C1.21253 16.748 0.928711 15.1938 0.928711 13.5449C0.928711 9.92283 2.18562 6.85488 4.69945 4.34105C7.21328 1.82723 10.2812 0.570312 13.9033 0.570312C17.5254 0.570312 20.5933 1.82723 23.1071 4.34105C25.621 6.85488 26.8779 9.92283 26.8779 13.5449C26.8779 15.1938 26.5941 16.748 26.0264 18.2076C25.4588 19.6673 24.6614 20.9647 23.6342 22.1V34.6286L13.9033 31.385L4.17236 34.6286ZM13.9033 23.2758C16.6063 23.2758 18.9039 22.3298 20.796 20.4376C22.6882 18.5455 23.6342 16.2479 23.6342 13.5449C23.6342 10.8419 22.6882 8.54428 20.796 6.65215C18.9039 4.76002 16.6063 3.81396 13.9033 3.81396C11.2003 3.81396 8.90268 4.76002 7.01055 6.65215C5.11842 8.54428 4.17236 10.8419 4.17236 13.5449C4.17236 16.2479 5.11842 18.5455 7.01055 20.4376C8.90268 22.3298 11.2003 23.2758 13.9033 23.2758ZM7.416 29.8037L13.9033 28.1413L20.3906 29.8037V24.776C19.4445 25.3166 18.4241 25.7424 17.3294 26.0532C16.2347 26.3641 15.0926 26.5195 13.9033 26.5195C12.714 26.5195 11.5719 26.3641 10.4772 26.0532C9.38246 25.7424 8.36207 25.3166 7.416 24.776V29.8037Z" fill="#A3A3A3"/>
					<rect x="5.86523" y="24.1875" width="16.2822" height="6.33203" fill="#A3A3A3"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(48,87,194,1) 0%, rgba(41,72,157,1) 100%)',
			additionalIcon: euroBadgeLogo,
			color: '#FFFFFF'
		},
		euroThird: {
			label: 'Бронзов медал',
			text: 'Завършил на трето място',
			icon: (
				<svg width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M17.8774 10.473C17.8774 11.513 17.4774 12.329 16.6774 12.841C17.7654 13.401 18.2294 14.393 18.2294 15.513C18.2294 17.625 16.8534 19.033 13.9894 19.033C11.5254 19.033 10.1334 18.073 9.49337 16.345C9.33337 15.897 9.44537 15.673 9.89337 15.513L11.0934 15.081C11.5414 14.921 11.7334 15.017 11.9254 15.497C12.2294 16.281 12.6774 16.681 13.9094 16.681C15.0134 16.681 15.5094 16.217 15.5094 15.433C15.5094 14.569 15.0614 14.185 14.1654 14.185H12.9014C12.4054 14.185 12.2454 14.025 12.2454 13.545V12.553C12.2454 12.057 12.4054 11.897 12.9014 11.897H13.8454C14.6454 11.897 15.2534 11.529 15.2534 10.729C15.2534 9.92897 14.7734 9.54497 13.8454 9.54497C12.6774 9.54497 12.3094 9.94497 12.0054 10.729C11.8134 11.209 11.6374 11.273 11.1734 11.145L9.97337 10.713C9.52537 10.553 9.41337 10.329 9.57337 9.88097C10.2134 8.15297 11.5254 7.19297 13.9894 7.19297C16.4694 7.19297 17.8774 8.45697 17.8774 10.473Z" fill="#C77B30"/>
					<path d="M4.17236 34.7927V22.2641C3.1452 21.1288 2.34781 19.8313 1.78017 18.3717C1.21253 16.9121 0.928711 15.3578 0.928711 13.709C0.928711 10.0869 2.18562 7.01894 4.69945 4.50511C7.21328 1.99129 10.2812 0.734375 13.9033 0.734375C17.5254 0.734375 20.5933 1.99129 23.1071 4.50511C25.621 7.01894 26.8779 10.0869 26.8779 13.709C26.8779 15.3578 26.5941 16.9121 26.0264 18.3717C25.4588 19.8313 24.6614 21.1288 23.6342 22.2641V34.7927L13.9033 31.549L4.17236 34.7927ZM13.9033 23.4399C16.6063 23.4399 18.9039 22.4938 20.796 20.6017C22.6882 18.7096 23.6342 16.412 23.6342 13.709C23.6342 11.0059 22.6882 8.70834 20.796 6.81621C18.9039 4.92409 16.6063 3.97802 13.9033 3.97802C11.2003 3.97802 8.90268 4.92409 7.01055 6.81621C5.11842 8.70834 4.17236 11.0059 4.17236 13.709C4.17236 16.412 5.11842 18.7096 7.01055 20.6017C8.90268 22.4938 11.2003 23.4399 13.9033 23.4399ZM7.416 29.9677L13.9033 28.3054L20.3906 29.9677V24.9401C19.4445 25.4807 18.4241 25.9064 17.3294 26.2173C16.2347 26.5281 15.0926 26.6835 13.9033 26.6835C12.714 26.6835 11.5719 26.5281 10.4772 26.2173C9.38246 25.9064 8.36207 25.4807 7.416 24.9401V29.9677Z" fill="#C77B30"/>
					<rect x="5.86523" y="24.957" width="16.2822" height="6.33203" fill="#C77B30"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(48,87,194,1) 0%, rgba(41,72,157,1) 100%)',
			additionalIcon: euroBadgeLogo,
			color: '#FFFFFF'
		},
		euroTop10: {
			label: 'Топ 10',
			text: 'Завършил между 4 - 10 място',
			icon: (
				<svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.6078 36.0522L10.4116 30.6692L4.35581 29.3235L4.94457 23.0995L0.823242 18.3894L4.94457 13.6793L4.35581 7.45526L10.4116 6.10952L13.6078 0.726562L19.3271 3.16571L25.0465 0.726562L28.2427 6.10952L34.2985 7.45526L33.7097 13.6793L37.8311 18.3894L33.7097 23.0995L34.2985 29.3235L28.2427 30.6692L25.0465 36.0522L19.3271 33.613L13.6078 36.0522Z" fill="#F5AA70"/>
					<path d="M16.1352 12.5306C16.6152 12.5306 16.7912 12.6906 16.7912 13.1866V23.2346C16.7912 23.7306 16.6152 23.8906 16.1352 23.8906H14.8392C14.3592 23.8906 14.1832 23.7306 14.1832 23.2346V15.7306L12.6312 16.6106C12.1352 16.8986 11.8632 16.7866 11.6392 16.3546L11.0792 15.3466C10.8552 14.9306 10.9672 14.7226 11.3992 14.4666L14.2952 12.7866C14.6472 12.5786 14.8232 12.5306 15.2232 12.5306H16.1352ZM17.8534 16.2746C17.8534 13.8426 19.4534 12.2906 22.2374 12.2906C25.0054 12.2906 26.5894 13.8426 26.5894 16.2746V20.1146C26.5894 22.5786 25.0054 24.1306 22.2374 24.1306C19.4534 24.1306 17.8534 22.5786 17.8534 20.1146V16.2746ZM23.9974 16.2426C23.9974 15.2186 23.3414 14.6746 22.2374 14.6746C21.1014 14.6746 20.4454 15.2186 20.4454 16.2426V20.1626C20.4454 21.1866 21.1014 21.7466 22.2374 21.7466C23.3414 21.7466 23.9974 21.1866 23.9974 20.1626V16.2426Z" fill="#2C4FAE"/>
				</svg>
			),
			background: 'linear-gradient(180deg, rgba(48,87,194,1) 0%, rgba(41,72,157,1) 100%)',
			additionalIcon: euroBadgeLogo,
			color: '#FFFFFF'
		},
	};

	return achievementsMap[key];
};

export const getWeekDates = () => {
	let today = new Date();
	let dayOfWeek = today.getDay();
	let startDate = new Date();
	let endDate = new Date();
	let hours = today.getHours();
	let minutes = today.getMinutes();

	// If today is Monday, adjust to the previous week
	if (
		(dayOfWeek === 1 && (hours > 7 || (hours === 7 && minutes >= 11))) ||
		(dayOfWeek === 2 && (hours < 7 || (hours === 7 && minutes < 10)))
	) {
		startDate.setDate(today.getDate() - 7);
		return 'Финално класиране за миналата седмица';
	} else if (dayOfWeek === 0) { // 0 is Sunday
		startDate.setDate(today.getDate() - 6);
	} else {
		startDate.setDate(today.getDate() - dayOfWeek + 1);
	}

	endDate.setTime(startDate.getTime());
	endDate.setDate(startDate.getDate() + 6);

	// Format the output based on whether the month is the same or different
	const startDay = startDate.getDate();
	const endDay = endDate.getDate();
	const startMonth = months[startDate.getMonth()].toLowerCase();
	const endMonth = months[endDate.getMonth()].toLowerCase();

	if (startDate.getMonth() === endDate.getMonth()) {
		return `${startDay} - ${endDay} ${endMonth}`;
	} else {
		return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
	}
};

const getCurrentOrPreviousMonth = () => {
	let today = new Date();
	let date = today.getDate();
	let hours = today.getHours();
	let minutes = today.getMinutes();
	let month = today.getMonth(); // January is 0!
	let year = today.getFullYear();

	// Check if today is the first day of the month and it's after 07:10 AM or if it's the second day and before 07:10 AM
	if ((date === 1 && (hours > 7 || (hours === 7 && minutes >= 11))) || (date === 2 && (hours < 7 || (hours === 7 && minutes < 10)))) {
		// If it is January and today is the first day, adjust for December of the previous year
		if (month === 0) {
			month = 11; // December
			year--; // Previous year
		} else {
			month--; // Previous month
		}
		return `Финално класиране за месец ${months[month]?.toLowerCase()}`;
	}

	// If today is the first day of the month but not within the specified time, adjust the month and year accordingly
	if (today.getDate() === 1) {
		if (month === 0) {
			month = 11; // December
			year--; // Previous year
		} else {
			month--; // Previous month
		}
	}

	// If the function has not returned by now, it implies either it's not the first or second day
	// or it's the first day but before 07:10 AM or the second day but after 07:10 AM
	// Hence, return the current or previous month name based on the date without considering the time condition
	return months[new Date(year, month).getMonth()]; // This line will execute if none of the above conditions are met
};

const periodTitles = {
	week: getWeekDates(),
	month: getCurrentOrPreviousMonth(),
};

export const getPeriodTitle = (period) => periodTitles[period];

