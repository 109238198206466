const GET_GAME_DATA = 'GET_GAME_DATA';
const SET_GAME_DATA = 'SET_GAME_DATA';
const START_LOADING_GAME_DATA = 'START_LOADING_GAME_DATA';
const STOP_LOADING_GAME_DATA = 'STOP_LOADING_GAME_DATA';
const SET_USER_MISSIONS = 'SET_USER_MISSIONS';

const getGameData = () => ({
	type: GET_GAME_DATA,
});

const setGameData = (payload) => ({
	type: SET_GAME_DATA,
	payload
});

const startLoadingGameData = () => ({
	type: START_LOADING_GAME_DATA
});

const stopLoadingGameData = () => ({
	type: STOP_LOADING_GAME_DATA
});

const setUserMissions = (payload) => ({
	type: SET_USER_MISSIONS,
	payload
});

export {
	GET_GAME_DATA,
	SET_GAME_DATA,
	START_LOADING_GAME_DATA,
	STOP_LOADING_GAME_DATA,
	SET_USER_MISSIONS,
	getGameData,
	setGameData,
	startLoadingGameData,
	stopLoadingGameData,
	setUserMissions
};