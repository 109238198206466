import { toast } from 'react-toastify';
import { GET_GAME_DATA, setGameData, startLoadingGameData, stopLoadingGameData } from '../actions/game.actions';
import httpProvider from '../../utils/httpProvider';

const getGameDataMiddleware = ({ dispatch, getState }) => next => async action => {
	if (action.type === GET_GAME_DATA) {
		dispatch(startLoadingGameData());

		try {
			const userId = getState().users?.loggedUser?.id;
			const query = userId ? `?userId=${userId}` : '';
			const response = await httpProvider.get(`game${query}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});

			if (response.data) {
				dispatch(setGameData(response.data));
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingGameData());
		}
	} else {
		return next(action);
	}
};

export {
	getGameDataMiddleware
};
